var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.$account.isFullAdmin || this.$account.permissions.canViewCurrencyConfiguration === true)?_c('v-sheet',{attrs:{"color":"white"}},[_c('v-dialog',{attrs:{"width":"400","max-width":"400"},model:{value:(_vm.removeCurrencyConfigDialog),callback:function ($$v) {_vm.removeCurrencyConfigDialog=$$v},expression:"removeCurrencyConfigDialog"}},[_c('v-form',{ref:"removeCurrencyConfigForm",on:{"submit":function($event){$event.preventDefault();return _vm.removeCurrencyConfigSubmit()}}},[_c('v-card',[_c('v-card-title',[_vm._v("Confirmation")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"add-role-col",attrs:{"cols":"12"}},[_vm._v(" Are you sure you want to remove this currency, "+_vm._s(this.fields.currencyFieldEdit)+"? ")])],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end pa-4"},[_c('v-btn',{staticClass:"mr-2",attrs:{"text":"","x-large":"","color":"primary"},on:{"click":function($event){_vm.removeCurrencyConfigDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"x-large":"","color":"primary","type":"submit","disabled":_vm.loading,"loading":_vm.loading}},[_vm._v("Ok")])],1)],1)],1)],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.currencyConfigList,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.currency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currency)+" ")]}},{key:"item.supported",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"color":"success","inset":"","disabled":""},model:{value:(item.supported),callback:function ($$v) {_vm.$set(item, "supported", $$v)},expression:"item.supported"}})]}},{key:"item.isDefault",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"color":"success","inset":"","disabled":""},model:{value:(item.isDefault),callback:function ($$v) {_vm.$set(item, "isDefault", $$v)},expression:"item.isDefault"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"remove-button",attrs:{"icon":""},on:{"click":function($event){return _vm.removeCurrencyConfig(item.id)}}},[_c('v-img',{attrs:{"src":require("@/assets/ic_delete_24px.svg"),"max-height":"24px","max-width":"24px"}})],1)]}}],null,true)})],1):_c('v-sheet',{attrs:{"color":"white"}},[_vm._v(" You are not allowed on this page... ")])}
var staticRenderFns = []

export { render, staticRenderFns }